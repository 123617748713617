/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import "./Layout.module.scss"
import "../css/_reset.scss"
import "../../animations/_customScroll.scss"

const Layout = ({ children }) => {
  return <main>{children}</main>
}

export default Layout
