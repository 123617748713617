module.exports = [{
      plugin: require('/Applications/XAMPP/xamppfiles/htdocs/lift-d-front/node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/Applications/XAMPP/xamppfiles/htdocs/lift-d-front/src/components/Layout/Layout.js"},
    },{
      plugin: require('/Applications/XAMPP/xamppfiles/htdocs/lift-d-front/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-46656003-1","head":true,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"sampleRate":100,"siteSpeedSampleRate":100},
    },{
      plugin: require('/Applications/XAMPP/xamppfiles/htdocs/lift-d-front/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
