import actionType from "../costants/actionsType"

const reducer = (state, action) => {
  switch (action.type) {
    case actionType.SET_CURRENT_PAGE_TYPE:
      return Object.assign({}, state, {
        currentPageState: action.pageType,
      })
    case actionType.SET_PREVIUS_PAGE_TYPE:
      return Object.assign({}, state, {
        previusPageState: action.pageType,
      })
    default:
      return state
  }
}

export default reducer
