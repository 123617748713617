// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-template-progetti-archiviati-js": () => import("/Applications/XAMPP/xamppfiles/htdocs/lift-d-front/src/templates/templateProgettiArchiviati.js" /* webpackChunkName: "component---src-templates-template-progetti-archiviati-js" */),
  "component---src-templates-template-servizi-js": () => import("/Applications/XAMPP/xamppfiles/htdocs/lift-d-front/src/templates/templateServizi.js" /* webpackChunkName: "component---src-templates-template-servizi-js" */),
  "component---src-templates-template-blank-js": () => import("/Applications/XAMPP/xamppfiles/htdocs/lift-d-front/src/templates/templateBlank.js" /* webpackChunkName: "component---src-templates-template-blank-js" */),
  "component---src-pages-404-js": () => import("/Applications/XAMPP/xamppfiles/htdocs/lift-d-front/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contatti-js": () => import("/Applications/XAMPP/xamppfiles/htdocs/lift-d-front/src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-index-js": () => import("/Applications/XAMPP/xamppfiles/htdocs/lift-d-front/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("/Applications/XAMPP/xamppfiles/htdocs/lift-d-front/src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-servizi-js": () => import("/Applications/XAMPP/xamppfiles/htdocs/lift-d-front/src/pages/servizi.js" /* webpackChunkName: "component---src-pages-servizi-js" */)
}

