import { createStore as reduxCreateStore } from "redux"
import reducer from "./reducer"

const initialState = {
  currentPageState: null,
  previusPageState: null,
}

const createStore = () => reduxCreateStore(reducer, initialState)
export default createStore
